import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProgramDataService {
  constructor(private http: HttpClient) { }

  readonly siteName = 'Onlinedegrees.UMassGlobal.edu'
  readonly externalInstitutionId = "UMG";

  // Start Dates
  getAllStartDates() {
    return this.http.get(`/core/programs/getstartandapplybydates?ProgramEnabled='Yes'&SiteName=${this.siteName}&ExternalInstitutionId=${this.externalInstitutionId}`);
  }

  getStartDatesById(programExternalId: any) {
    return this.http.get(`/core/programs/getstartdatesbyid?ProgramExternalId=${programExternalId}&SiteName=${this.siteName}`);
  }

}
