import {gql} from 'apollo-angular'
import { FOOTER_FIELDS, HEADER_FIELDS, MULTI_COLUMN_FIELDS, BIG_PHOTO_FIELDS, HORIZONTAL_CALLOUT_FIELDS, CONTENT_BLOCK_FULL_FIELDS, CONTENT_BLOCK_RR_FIELDS, LARGE_TWO_COLUMN_FIELDS, HERO_FIELDS, START_DATES_FIELDS, FAQ_ACCORDION_FIELDS, FOOTER_CTA_FIELDS, HORIZONTAL_TAB_FIELDS, VERTICAL_TAB_FIELDS } from 'src/app/gql'
import { QUOTES_FIELDS } from 'src/app/gql/quotes'

const GET_OC_Collection =  gql`
query optimizedCombo($slug: String!, $preview: Boolean! = false) {
  optimizedComboCollection(where: { url: $slug }, preview: $preview, limit: 1) {
    items {
      hTag
      header
      pageTitleExternal
      metaTitle
      metaOgSite
      metaOgImage {
        url
      }
      metaDescription
      canonical
      robotsTag
			hero { 
        ...HERO_FIELDS
      }
      sifAreaOfStudy {
        sifAreaOfStudy
      }
      sifProgramCode {
        sifProgramCode
      }
      sifDegree {
        sifDegree
      }
      disclaimersCollection(limit: 15) {
        items {
          disclaimer {
            json
          }
        }
      }
      contentAreaCollection(limit:20) {
        items {
          ...MULTI_COLUMN_FIELDS
          ...CONTENT_BLOCK_FULL_FIELDS
          ...BIG_PHOTO_FIELDS
          ...HORIZONTAL_CALLOUT_FIELDS
          ...QUOTES_FIELDS
          ...CONTENT_BLOCK_RR_FIELDS
          ...LARGE_TWO_COLUMN_FIELDS
          ...START_DATES_FIELDS
          ...FAQ_ACCORDION_FIELDS
          ...FOOTER_CTA_FIELDS
          ...HORIZONTAL_TAB_FIELDS
          ...VERTICAL_TAB_FIELDS
        }
      }
      overrideHeader {
        ...on WebsiteHeader {
          ...HEADER_FIELDS
        }
      }
      overrideFooter {
        ...on WebsiteFooter {
          ...FOOTER_FIELDS
        }
      }
    }
  }
}
${HERO_FIELDS}
${MULTI_COLUMN_FIELDS}
${CONTENT_BLOCK_FULL_FIELDS}
${HEADER_FIELDS}
${FOOTER_FIELDS}
${BIG_PHOTO_FIELDS}
${QUOTES_FIELDS}
${HORIZONTAL_CALLOUT_FIELDS}
${CONTENT_BLOCK_RR_FIELDS}
${LARGE_TWO_COLUMN_FIELDS}
${START_DATES_FIELDS}
${FAQ_ACCORDION_FIELDS}
${FOOTER_CTA_FIELDS}
${HORIZONTAL_TAB_FIELDS}
${VERTICAL_TAB_FIELDS}`

export {GET_OC_Collection}
