import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { StartDateItem, StartDatesService } from '../../core/services/start-dates.service';

@Component({
  selector: 'app-start-dates',
  templateUrl: './start-dates.component.html',
  styleUrls: ['./start-dates.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class StartDatesComponent implements OnInit, AfterViewInit {
  @Input({ required: true }) public startDates!: any;
  @Input() public startDateTermConfig: any;

  backgroundColor = '';
  sectionHeader = '';
  sectionSubheader = '';
  headerOnly = '';
  startDateItemsArray: Array<StartDateItem> = [];
  showTerm = true;

  constructor(
    @Inject(DOCUMENT) private dom: Document, private startDatesService: StartDatesService) {
  }

  ngOnInit(): void {
    this.backgroundColor = this.getBackgroundColorClass(this.startDates?.backgroundColor);
    this.sectionHeader = this.startDates?.header;
    this.sectionSubheader = this.startDates?.introCopy?.json;
    this.headerOnly = this.sectionHeader && !this.sectionSubheader ? "headerOnly" : "";
    this.showTerm = this.startDates?.showTerm != null ? this.startDates?.showTerm : true;

    if (this.startDates?.startDateItemsCollection?.items?.length) {
      this.getStartDateItems();
    }
  }

  ngAfterViewInit(): void {
    const activeSlick = Array.from(this.dom.getElementsByClassName('slick-active') as HTMLCollectionOf<HTMLElement>);
    activeSlick.forEach(element => {
      element.tabIndex = -1;
    });
  }

  slideConfig = {
    "infinite": true,
    "slidesToShow": 3,
    "arrows": true,
    "slidesToScroll": 3,
    "adaptiveHeight": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  getStartDateItems() {
    try {
      const datesJson = JSON.parse(JSON.stringify(this.startDates?.startDateItemsCollection?.items));
      const currentDate = Date.now();

      // Coverts start dates data into an array of StartDateItem.
      datesJson.map((item: any) => {
        const date = new Date(item.calendarDate.replace(/-/g, '\/').replace(/T.+/, ''));
        //check if item.applyBydate is not null 
        const applyByDate = item.applyByDate ? new Date(item.applyByDate.replace(/-/g, '\/').replace(/T.+/, '')) : null;
        const weekFuture = currentDate + 1000 * 60 * 60 * 24 * 14;

        if ((applyByDate != null && applyByDate.getTime() > currentDate) || date.getTime() > weekFuture) {
          this.startDateItemsArray.push(new StartDateItem(date, item.startDateDescriptor, this.startDateTermConfig, applyByDate));
        } 
      });


      // Sorts the dates.
      if (this.startDateItemsArray.length) {
        this.sortArrayByDate();
      }
    } catch (e) {
      console.error("Error getting the start date items. ", e)
    }
  }

  sortArrayByDate() {
    this.startDateItemsArray.sort((a, b) =>
      a.date === b.date ? 0 : (a.date > b.date ? 1 : -1)
    );
  }

  getBackgroundColorClass(bckgrd: string): string {
    return bckgrd ? bckgrd : "White";
  }
}
