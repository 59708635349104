import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd, ActivatedRoute} from '@angular/router';
import { ContentfulService } from '../../core/services/contentful.service';
import { GET_OC_Collection } from './optimized-combo-query';
import { Constants, ComponentType } from '../../config/app.config';
import { SEOService } from '../../core/services/seo.service';
import { DOCUMENT, Location } from '@angular/common';
import { HeaderFooterService } from 'src/app/core/services/header-footer.service';
import { StartDatesService } from 'src/app/core/services/start-dates.service';
import { CommonService } from '../../core/services/common.service';
import { ProgramDataService } from '../../core/services/program-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-optimized-combo',
  templateUrl: './optimized-combo.component.html',
  styleUrls: ['./optimized-combo.component.less'],
})
export class OptimizedComboComponent implements OnInit, OnDestroy {
  [x: string]: any;

  public pageData: any;
  loading = false;
  error: any;
  currentRoute: any;
  routerEvent: any;
  noRoutesConfigured = false;

  startDates: any;
  startDateTermConfig: any;
  showStartDate = false;
  ComponentType = ComponentType;

  aosPreselected = '';
  degreePreselected = '';
  programPreselected = '';

  private startDateConfigSub!: Subscription;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private contentfulService: ContentfulService,
    public constant: Constants,
    private headerService: HeaderFooterService,
    @Inject(DOCUMENT) private dom: Document,
    private seo: SEOService,
    private location: Location,
    private startDatesService: StartDatesService,
    private programDataService: ProgramDataService,
    private commonService: CommonService) {

  }

  ngOnInit(): void {
    this.currentRoute = this.router.url;
    this.getPageData();
    this.listenRouterEvents();

    this.startDateConfigSub = this.startDatesService.startDateTermConfiguration.subscribe((data: any) => {
      this.startDateTermConfig = data.length == 0 ? this.startDatesService.getDefaultStartDateTermConfig() : data;
    });
  }

  ngOnDestroy(): void {
    this.routerEvent.unsubscribe();
    this.startDateConfigSub?.unsubscribe();
  }

  /**
   * Listen for route change and fetches page data based on the slug
   */
  private listenRouterEvents() {
    this.routerEvent = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
          this.currentRoute = event.url;
          this.getPageData();
      }
  });
  }

  /**
   * Method to get the page data based on the url fragment
   */
  private getPageData() {
    try {
      this.contentfulService.getPageData(GET_OC_Collection, {slug: this.parseSlug(this.currentRoute)}).subscribe({
        next:  (res) => {
          if (res?.data?.optimizedComboCollection?.items?.length > 0) {
            this.pageData = res?.data?.optimizedComboCollection.items[0];
            this.getProgramStartDates();
            this.setSEOFields();
            this.checkForHeaderFooterOverrides();
            this.setSIFFormPreFillData();
          }
          else  {
            this.router.navigateByUrl('404')
            this.pageData = null;
          }
        }
      });
    } catch (e) {
      console.error("failed gql query")
    }
  }

  parseSlug(url: string): string {
    // trim the '/' at the beginning
    let slug = url.slice(1, url.length);
    // remove query string params from slug
    if (slug.split('?')[0] === '') {
      slug = this.constant.DefaultHomePage;
    }
    return slug
  }

  /**
   * Sanitizing query string params 
   */
  sanitizeQSP(){
    let queryParams : any;
    let hash = '';
    this.route.queryParams.subscribe(params => {
      queryParams = params;
    });
    if(Object.keys(queryParams).length > 0){
      const qsp = JSON.parse(JSON.stringify(queryParams))
      const values = Object.values(qsp);
      values.map((item: any, index: any) =>{
        if(item.includes(' and ') || item.includes(' or ')){
          values[index] = '';
        }
      });
      Object.keys(qsp).forEach((k, i) => {
        qsp[k] = values[i];
      });
      const baseRoute = this.currentRoute.split('?')[0];
      if(this.currentRoute.includes('#')){
        hash = this.currentRoute.split('#')[1];
        this.currentRoute = baseRoute + '?'+ Object.keys(qsp).map(key => `${key}=${qsp[key]}`).join("&") + '#' + hash; 
      } else {
        this.currentRoute = baseRoute + '?'+ Object.keys(qsp).map(key => `${key}=${qsp[key]}`).join("&"); 
      }
      this.location.replaceState(this.currentRoute.slice(1, this.currentRoute.length))
    }
  }

  setSIFFormPreFillData() {
    const aosPreselected = this.pageData.sifAreaOfStudy?.sifAreaOfStudy;
    const progPreselected = this.pageData.sifProgramCode?.sifProgramCode;
    const degPreselected = this.pageData.sifDegree?.sifDegree;

    if (aosPreselected && progPreselected && degPreselected) {
      this.aosPreselected = aosPreselected;
      this.programPreselected = progPreselected;
      this.degreePreselected = degPreselected; 
    } else {
      this.aosPreselected = '';
      this.programPreselected = '';
      this.degreePreselected = '';
    }
  }

  /**
   * Gets the data from CMS fields and set to seo service for creating meta tags.
   */
  setSEOFields() {
    this.sanitizeQSP();
    this.seo.setTitle(this.pageData.pageTitleExternal);
    this.seo.setRobots(this.pageData.robotsTag);
    this.seo.metaTags.description = this.pageData.metaDescription || '';
    this.seo.metaTags['og:title'] = this.pageData.pageTitleExternal;
    this.seo.metaTags['og:description'] = this.pageData.metaDescription || '';
    this.seo.metaTags['og:site_name'] = this.pageData.metaOgSite;
    this.seo.metaTags['og:url'] = this.constant.domain + this.currentRoute.slice(1, this.currentRoute.length);
    this.seo.metaTags['og:image'] = this.pageData.metaOgImage?.url || '';
    this.seo.addOrUpdateMetaTags();
    this.seo.addOrUpdateCanonicalURL(this.pageData.canonical, this.currentRoute.slice(1, this.currentRoute.length));
  }

  checkForHeaderFooterOverrides() {
    // check for header override
    const header = this.pageData.overrideHeader ? this.pageData.overrideHeader : false;
    this.headerService.headerConfiguration.next(header);
    // check for footer override
    const footer = this.pageData.overrideFooter ? this.pageData.overrideFooter : false;
    this.headerService.footerConfiguration.next(footer);
  }
 
  /**
  * To fetch the start dates from the program database. 
  */
  getProgramStartDates() {
    try {
      const startDateData = this.pageData?.contentAreaCollection?.items.find((item: any) => item.__typename === ComponentType.StartDates);
     
      if (startDateData) {
        // There should only be one start date component in a page.
        this.startDates = JSON.parse(JSON.stringify(startDateData));

        // if startDateItemsCollection.items is empty then fetch program start dates from DB otherwise continue with values from contentful.
        if (this.startDates?.startDateItemsCollection?.items.length == 0 && this.commonService.isPlatformBrowser()) {
          const programId = this.startDates?.programId?.split('-')[0]?.toUpperCase();

          if (this.startDates?.startDateSelection == "Show Program Dates" && (programId != null && programId != '')) {
            this.programDataService.getStartDatesById(programId).subscribe((response: any) => {
              if (response?.startAndApplyByDate.length > 0) {
                this.formatStartDateItems(response.startAndApplyByDate);
              }
            });
          } else {
            this.programDataService.getAllStartDates().subscribe((response: any) => {
              if (response?.startAndApplyByDate.length > 0) {
                this.formatStartDateItems(response.startAndApplyByDate);
              }
            });
          }
        } else {
          this.showStartDate = true;
        }
      }      
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * To format the values from the DB to match the contentful data structure.
   * @param startDates start dates from the DB
   */
  formatStartDateItems(startDates: any) {
    const formattedStartDates: any[] = [];

    startDates.forEach((item: any) => {
      if (item?.startDate?.length > 0) {
        formattedStartDates.push({ __typename: 'StartDateItems', calendarDate: item?.startDate, startDateDescriptor: item?.startDateDescriptor, applyByDate: item?.applyByDate });
      }
    });

    this.startDates = {
      ...this.startDates,
      startDateItemsCollection: {
        ...this.startDates.startDateItemsCollection,
        items: formattedStartDates
      }
    };

    this.showStartDate = true;
  }
}