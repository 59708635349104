<section *ngIf="!formSubmitted" class="White" [class.hero-sif]="heroSif">
  <div class="reqInfo">
      <div class='grid-container sif-container'>
        <div class="rr-sif-container">
          <ng-container *ngIf="(heroSif && (heroSifStep === 'Step1')) || !heroSif" >
            <div class="upper-container"> 
              <app-h-tag [hTag]="formData?.hTag" [className]="'header h2'" [content]="formData?.header"></app-h-tag>
              <div class="text-content" *ngIf="formData?.subhead">
                <p>{{ formData?.subhead }}</p>
              </div>
            </div>
          </ng-container>
              <div class="lower-container" [class]="heroSifStep">
                  <div class='small-12'>
                    <form [formGroup]="requestInfoForm" autocomplete="off" (ngSubmit)="onSubmit()">
                      <!-- Step 1 start -->
                      <ng-container *ngIf="((heroSif && (heroSifStep === 'Step1')) || !heroSif)">

                        <div class="zip" *ngIf="isDomestic">
                          <label for="zip">{{formData?.zipCodeLabel}}</label>
                          <input id="zip" numbersOnly maxlength="5" minlength="5" type="text" (change)="validateZipCode($event)" formControlName="zip">
                          <p *ngIf="formControl['zip'].touched && formControl['zip'].errors" class="text-danger">{{formData?.zipErrorMessage}}</p>
                        </div>
                          <div class="degree">
                            <label for="degree">{{formData?.degreeLevelLabel}}</label>
                            <div class="select-wrapper">
                              <select id="degree" formControlName="degree" (change)="changedDegreeLevel($event)" required>
                                <option selected disabled value="">Select Degree Type</option>
                                <option *ngFor="let level of degreeLevel" [value]="level.code">
                                  {{ level.name }}
                                </option>
                              </select>
                            </div>
                            <p *ngIf="formControl['degree'].touched && formControl['degree'].errors" class="text-danger">{{formData?.degreeLevelErrorMessage}}</p>
                          </div>
                      </ng-container>
                      <!-- Step 2 start -->
                      <ng-container *ngIf="(heroSif && (heroSifStep === 'Step2')) || !heroSif" >
                      <div class="areaOfStudy">
                        <label for="areaOfStudy">{{formData?.aosLabel}}</label>
                        <div class="select-wrapper">
                          <select id="areaOfStudy" formControlName="areaOfStudy" (change)="changedAOS($event)">
                            <option selected disabled value="">Select Interest</option>
                            <option *ngFor="let area of aos" [value]="area">
                              {{ area }}
                            </option>
                          </select>
                        </div>
                        <p *ngIf="formControl['areaOfStudy'].touched && formControl['areaOfStudy'].errors" class="text-danger">{{formData?.aosErrorMessage}}</p>
                      </div>

                      <div class="programName">
                        <label for="programName">{{formData?.programLabel}}</label>
                        <div class="select-wrapper">
                          <select id="programName" formControlName="programName" (change)="changedProgram($event)">
                            <option selected disabled value="">Select Program</option>
                            <option *ngFor="let program of programs" [value]="program">
                              {{ program }}
                            </option>
                          </select>
                        </div>
                        <p *ngIf="formControl['programName'].touched && formControl['programName'].errors" class="text-danger">{{formData?.programErrorMessage}}</p>
                      </div>

                      <div class="startDate">
                        <label for="startDate">{{formData?.sessionLabel}}</label>
                        <div class="select-wrapper">
                          <select id="startDate" formControlName="startDate">
                            <option selected disabled value="">Select Session</option>
                            <option *ngFor="let session of sessions" [value]="session">
                              {{ session }}
                            </option>
                          </select>
                        </div>
                        <p *ngIf="formControl['startDate'].touched && formControl['startDate'].errors" class="text-danger">{{formData?.sessionErrorMessage}}</p>
                      </div>

                    </ng-container>
                      <!-- Step 3 start -->
                      <ng-container *ngIf="(heroSif && (heroSifStep === 'Step3')) || !heroSif" >
                        <div class="name ottra">
                          <label for="Name">name</label>
                          <input id="Name" type="text" formControlName="name" autocomplete="disabled" data-lpignore="true" tabindex="-1" aria-hidden="true">                         
                        </div>

                        <div class="fields-wrapper">
                          <div class="firstName">
                            <label for="first-name">{{formData?.firstNameLabel}}</label>
                            <input id="first-name" type="text" inputRef (blur)="updateFieldValue('firstName')" formControlName="firstName">
                            <p *ngIf="formControl['firstName'].touched && formControl['firstName'].errors" class="text-danger">{{formData?.firstNameErrorMessage}}</p>
                          </div>
                          <div class="lastName">
                            <label for="last-name">{{formData?.lastNameLabel}}</label>
                            <input id="last-name" type="text" inputRef (blur)="updateFieldValue('lastName')" formControlName="lastName">
                            <p *ngIf="formControl['lastName'].touched && formControl['lastName'].errors" class="text-danger">{{formData?.lastNameErrorMessage}}</p>
                          </div>
                        </div>

                        <div class="email ottra">
                          <label for="Email">Email</label>
                          <input id="Email" type="email" formControlName="email" autocomplete="off" data-lpignore="true" tabindex="-1" aria-hidden="true">                          
                        </div>
                        <div class="sifEmail">
                          <label for="sifEmail">{{formData?.emailLable}}</label>
                          <input id="sifEmail" type="email" formControlName="sifEmail">
                          <p *ngIf="formControl['sifEmail'].touched && formControl['sifEmail'].errors" class="text-danger">{{formData?.emailErrorMessage}}</p>
                        </div>
                        <div class="fields-wrapper" *ngIf="isDomestic && internationalValidationCompleted">
                          <div class="phone">
                            <label for="phone">{{formData?.phoneLabel}}</label>
                            <input [mobMask]="maskphone" id="phone" type="text" formControlName="phone" maxlength="20">
                            <p *ngIf="formControl['phone'].touched && formControl['phone'].errors" class="text-danger">{{formData?.phoneErrorMessage}}</p>
                          </div>
                          <div class="zip">
                            <label for="zip">{{formData?.zipCodeLabel}}</label>
                            <input id="zip" numbersOnly maxlength="5" minlength="5" type="text" (change)="validateZipCode($event)" formControlName="zip">
                            <p *ngIf="formControl['zip'].touched && formControl['zip'].errors" class="text-danger">{{formData?.zipErrorMessage}}</p>
                          </div>
                        </div>
                        <div class="country" *ngIf="showCountryField">
                          <label for="country">{{formData?.countryregionLabel}}</label>
                          <div class="select-wrapper">
                            <select id="country" formControlName="country" (change)="changedCountryCode($event)">
                              <option selected disabled value="">Select Country</option>
                              <option *ngFor="let code of countryCodes" [value]="code">
                                {{ code }}
                              </option>
                            </select>
                          </div>
                          <p *ngIf="formControl['country'].touched && formControl['country'].errors" class="text-danger">{{formData?.countryregionErrorMessage}}</p>
                        </div>
                        <div class="fields-wrapper">
                          <div class="phoneCountryCode" *ngIf="showCountryField && !isDomestic">
                            <label for="phoneCountryCode">{{formData?.countryCodeLabel}}</label>
                            <input numbersOnly type="text" formControlName="phoneCountryCode" maxlength="5" />
                            <p *ngIf="formControl['phoneCountryCode'].touched && formControl['phoneCountryCode'].errors" class="text-danger">{{formData?.countryCodeErrorMessage}}</p>
                          </div>
                          <div class="phone" *ngIf="!isDomestic">
                            <label for="phone">{{formData?.phoneLabel}}</label>
                            <input [mobMask]="maskphone" id="phone" type="text" formControlName="phone" maxlength="20">
                            <p *ngIf="formControl['phone'].touched && formControl['phone'].errors" class="text-danger">{{formData?.phoneErrorMessage}}</p>
                          </div>
                        </div>
                        <div class="fields-wrapper">
                          <div class="military checkbox-wrapper">
                            <input type="checkbox" formControlName="military" id="military" class="form-check-input" />
                            <label for="military">{{formData?.militaryQuestionLabel}}</label>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="(heroSif && (heroSifStep === 'Step4')) || !heroSif" >
                        <!-- Step 4 starts -->
                        <div class='disclaimer show-for-tablet'>
                          <div class="tcpa-disclaimer" *ngIf="formData?.tcpaDisclaimer && !isGDPR">
                            <app-disclaimer-item [disclaimerItem]="formData?.tcpaDisclaimer"></app-disclaimer-item>
                            <a *ngIf="isDomestic && formData?.tooltipTitle && formData?.tooltipCopy.json"
                              class="messageTooltip" role="tooltip" tabindex="0">
                              {{formData?.tooltipTitle}}
                              <app-contentful-rich-text class="tooltiptext" [document]="formData?.tooltipCopy.json"></app-contentful-rich-text>
                            </a>
                          </div>
                          <div class="gdpr-disclaimer" *ngIf="formData?.gdprDisclaimer && isGDPR">
                            <input type="checkbox" name="isAccept" [checked]="disclaimerAccepted" (change)="disclaimerAccepted = !disclaimerAccepted;">
                            <app-disclaimer-item [disclaimerItem]="formData?.gdprDisclaimer"></app-disclaimer-item>
                          </div>
                        </div> 
                        <div id="challenge" class="challenge" *ngIf="recaptchaLoaded">
                          <re-captcha (resolved)="captchaChallengeResolved($event)" formControlName="recaptchaChallengeToken"                                      
                                      theme="light" type="image" size="normal" required></re-captcha>
                        </div>                    
                      
                      </ng-container>
                      <!-- Common  -->
                      <div class="button-container">
                        <button *ngIf="!heroSif || heroSifStep === 'Step4'" type="submit" class="btn primary-button" [disabled]=" recaptchaLoaded ? !isReCaptchaValid : isReCaptchaValid">
                          {{isSubmitting? formData?.submitLoaderText : formData?.linkTitle}}
                          <div id="newSifSubmitLoader" class="sifSubmitLoader" [ngClass]="{'active': isSubmitting === true}"></div>
                        </button>
                        <button *ngIf="heroSif && heroSifStep === 'Step1'" type="button" class="btn primary-button" (click)="validateSteps()" [disabled]="!requestInfoForm.get('zip')?.valid || !requestInfoForm.get('degree')?.valid">
                          Continue
                        </button>
                        <!-- Added duplicated button to avoid focus issues -->
                        <button *ngIf="heroSif && heroSifStep === 'Step2'" type="button" class="btn primary-button" (click)="validateSteps()">
                          Continue
                        </button>
                        <button *ngIf="heroSif && heroSifStep === 'Step3'" type="button" class="btn primary-button" (click)="validateSteps()">
                          Continue
                        </button>
                        <button type="button" class="btn secondary-button" *ngIf="heroSif && (heroSifStep === 'Step2' || heroSifStep === 'Step3' || heroSifStep === 'Step4')" (click)="backBtn()" [disabled]="isSubmitting">
                          <span>Back</span>
                        </button>
                      </div>
                    </form>
                  </div>
              </div>
            </div>
      
      </div>
  </div>
</section>

<app-modal *ngIf="formData?.gdprCheckBoxNotice" [content]="formData?.gdprCheckBoxNotice"></app-modal>

<!-- Post SIF messages  -->
<section class="White" *ngIf="formSubmitted && !ycbmSubmitted" [class.hero-sif]="heroSif">
  <div class='grid-container'>
  <div class='grid-x'>
  <div class="thankyou">
            <div class="content-container text-center">
              <div class="schedule-now-intro" *ngIf="!isSkip && showBookingButton">
                <h2 class="header h2" *ngIf="formData.scheduleAnAppointment.header">{{formData.scheduleAnAppointment.header}}</h2>
                <div class="subheader-text" *ngIf="formData.scheduleAnAppointment.subhead">
                  <p>{{formData.scheduleAnAppointment.subhead}}</p>
                </div>
                <div class='text-content'>
                  <app-contentful-rich-text *ngIf="formData.scheduleAnAppointment.content" [document]="formData.scheduleAnAppointment.content.json"></app-contentful-rich-text>
                </div>
                <div class="button-container">
                  <button class="btn primary-button" (click)="triggerYCBM()">Schedule Now</button>
                  <div class="link-container" *ngIf="formData.scheduleAnAppointment.linksCollection.items.length">
                    <ng-container *ngFor="let button of formData.scheduleAnAppointment.linksCollection.items">
                      <app-link *ngIf="button.linkTitle && (button.internalLink?.url || button.externalLink || button.documentLink)" [childClass]="'btn primary-button'"
                                [internalLink]="button.internalLink?.url" [linkTitle]="button.linkTitle" [target]="button.linkTarget"
                                [externalLink]="button.externalLink" [documentLink]="button.documentLink"></app-link>
                    </ng-container>
                  </div>
                  <button class="btn secondary-button" id="ycbm-skip-now" (click)="skipYCBM()">Skip</button>
                </div>
              </div>
              <div class="no-ycbm-intro" *ngIf="!isSkip && !showBookingButton">
                <h2 class="header h2" *ngIf="formData.suppressionThankYou.header">{{formData.suppressionThankYou.header}}</h2>
                <div class="subheader-text" *ngIf="formData.suppressionThankYou.subhead">
                  <p>{{formData.suppressionThankYou.subhead}}</p>
                </div>
                <div class='text-content'>
                  <app-contentful-rich-text *ngIf="formData.suppressionThankYou.content" [document]="formData.suppressionThankYou.content.json"></app-contentful-rich-text>
                </div>
                <div class="button-container">
                  <div class="link-container" *ngIf="formData.suppressionThankYou.linksCollection.items.length">
                    <ng-container *ngFor="let button of formData.suppressionThankYou.linksCollection.items">
                      <app-link *ngIf="button.linkTitle && (button.internalLink?.url || button.externalLink || button.documentLink)" [childClass]="'btn primary-button'"
                                [internalLink]="button.internalLink?.url" [linkTitle]="button.linkTitle" [target]="button.linkTarget"
                                [externalLink]="button.externalLink" [documentLink]="button.documentLink"></app-link>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="skip-intro" *ngIf="isSkip">
                <h2 class="header h2" *ngIf="formData.thankYou.header">{{formData.thankYou.header}}</h2>
                <div class="subheader-text" *ngIf="formData.thankYou.subhead">
                  <p>{{formData.thankYou.subhead}}</p>
                </div>
                <div class='text-content'>
                  <app-contentful-rich-text *ngIf="formData.thankYou.content" [document]="formData.thankYou.content.json"></app-contentful-rich-text>
                </div>
                <div class="button-container">
                  <div class="link-container" *ngIf="formData.thankYou.linksCollection.items.length">
                    <ng-container *ngFor="let button of formData.thankYou.linksCollection.items">
                      <app-link *ngIf="button.linkTitle && (button.internalLink?.url || button.externalLink || button.documentLink)" [childClass]="'btn primary-button'"
                                [internalLink]="button.internalLink?.url" [linkTitle]="button.linkTitle" [target]="button.linkTarget"
                                [externalLink]="button.externalLink" [documentLink]="button.documentLink"></app-link>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  </div>
</section>

<section *ngIf="ycbmSubmitted" [ngClass]="{'hero-ycbm hero-sif': heroSif}">
  <div class='grid-container'>
    <div class='grid-x' id="booking-content" [ngStyle]="{'max-height': heroHeight ? heroHeight + 'px': 'auto'}">
      <div id="iframeLoader" class="iframeLoader"></div>
    </div>
  </div>
</section>
