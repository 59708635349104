import { DOCUMENT} from '@angular/common';
import { Component, Inject, Input, OnChanges,ViewEncapsulation, ChangeDetectorRef, Injector, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Constants } from 'src/app/config/app.config';
import { ContentfulService } from 'src/app/core/services/contentful.service';
import countriesData from '../../../assets/data/countries.json';
import programData from '../../../assets/data/SIFCatalog.json';
import { SIFService } from 'src/app/core/services/sif.service';
import { GeoLocationService } from 'src/app/core/services/geolocation.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { YcbmService } from 'src/app/core/services/ycbm.service';
import { UserAgentService } from 'src/app/core/services/user-agent.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { CommonService } from 'src/app/core/services/common.service';
import { QueryStringService } from '../../core/services/query-string.service';
import { CookieService } from '../../core/services/cookie.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { LINK_FIELDS, SIF_FORM } from 'src/app/gql';
import { gql } from 'apollo-angular';
import { ProgramDataService } from '../../core/services/program-data.service';
import { StartDatesService } from '../../core/services/start-dates.service';
import { Subscription } from 'rxjs';

type HeroSteps = '' | 'Step1' | 'Step2' | 'Step3' | 'Step4';

@Component({
  selector: 'app-sif-form',
  templateUrl: './sif-form.component.html',
  styleUrls: ['./sif-form.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class SifFormComponent implements OnChanges, OnDestroy {
  @Input() heroSif = false;
  @Input() heroId = '';
  @Input() aosPreselected = '';
  @Input() degreePreselected = '';
  @Input() programPreselected = '';
  programList: any;
  selectedDegreeLevel: any;
  zipCode: any;
  formData: any;
  leadImportId: any;
  userAgentData: any;
  sifData: any = {};
  degreeLevel: Array<any> = [];
  countryCodes: Array<string> = [];
  aos: Array<string> = [];
  programs: Array<string> = [];
  sessions: Array<string> = [
    "Spring 2025 Session I",
    "Spring 2025 Session II",
    "Summer 2025 Session I",
    "Summer 2025 Session II"
  ];
  startDateTermConfig: any;

  currentRoute = "";
  isDomestic = true;
  maskphone = true;
  formSubmitted = false;
  ycbmSubmitted = false;
  isSkip = false;
  isGDPR = false;
  isSubmitting = false;
  disclaimerAccepted = false;
  showBookingButton = false;
  showCountryField = false;
  isReCaptchaValid = false;
  recaptchaSize: any;
  
  requestInfoForm !: FormGroup;
  internationalValidationCompleted = false;

  heroHeight: number | undefined = 0;
  heroSifStep: HeroSteps = '';

  private startDateConfigSub!: Subscription;
  private isDomesticSub!: Subscription;
  private pageDataSub!: Subscription;


  
  public recaptchaLoaded = false;  
  @ViewChild('GDPR') GDPRModal!: ModalComponent;

  constructor(private router: Router,
    private contentfulService: ContentfulService,
    public constant: Constants,
    @Inject(DOCUMENT) private dom: Document,
    private fb: FormBuilder,
    private sifService: SIFService,
    private dataLayer: DataLayerService,
    private ycbmService: YcbmService,
    private geoLocationService: GeoLocationService,
    private modalService: ModalService,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private userAgentService: UserAgentService,
    private queryStringHandler: QueryStringService,
    private cookieHandler: CookieService,
    private injector: Injector,
    private startDatesService: StartDatesService,
    private programDataService: ProgramDataService) {
  }
  ngOnChanges(): void {
    this.buildForm();
    this.getURLParams();
    this.setUserAgentData();
    this.setGoogleAnalyticsData();
    this.populateDegreeLevel();
    
    this.setRecaptchaValues();    
    if(this.heroSif) {
      this.getPageData();
    }

    this.startDateConfigSub = this.startDatesService.startDateTermConfiguration.subscribe((data: any) => {
      this.startDateTermConfig = data.length == 0 ? this.startDatesService.getDefaultStartDateTermConfig() : data;
    });

    this.heroSifStep = this.heroSif ? 'Step1' : '';
    if(this.aosPreselected && this.programPreselected && this.degreePreselected) {
      this.preFillForm();
    }
    if(this.commonService.isPlatformBrowser()) {
      this.checkIfInternational();
    }
  }

  ngOnDestroy(): void {
    this.startDateConfigSub?.unsubscribe();
    this.isDomesticSub?.unsubscribe();
    this.pageDataSub?.unsubscribe();
  }

  updateLinkAttribute() {
    const getLinkElement = this.dom.querySelectorAll('.disclaimer .rich-text-content a');
    getLinkElement.forEach((userItem) => {
      userItem.setAttribute("target", "_blank");
    });
  }

  getURLParams() {
    this.currentRoute = this.router.url.slice(1, this.router.url.length);
    let source = this.queryStringHandler.getParameter('source', true) || this.constant.SIFSourceID;
    let ve = this.queryStringHandler.getParameter('ve', true) || this.constant.SIFVendorID;
    let utm_source = this.queryStringHandler.getParameter('utm_source', true) || '';
    let utm_medium = this.queryStringHandler.getParameter('utm_medium', true) || '';
    let utm_campaign = this.queryStringHandler.getParameter('utm_campaign', true) || '';
    let utm_content = this.queryStringHandler.getParameter('utm_content', true) || '';
    let utm_term = this.queryStringHandler.getParameter('utm_term', true) || '';
    let loadtest = this.queryStringHandler.getParameter('loadtest', true) || '';
    let qsp = this.dom.location.search || '';
    this.requestInfoForm.get('queryString')?.setValue(qsp);
    this.requestInfoForm.get('url')?.setValue(this.dom.URL);
    this.requestInfoForm.get('source')?.setValue(source?.toUpperCase());
    this.requestInfoForm.get('vendor')?.setValue(ve?.toUpperCase());
    this.requestInfoForm.get('utmSource')?.setValue(utm_source);
    this.requestInfoForm.get('utmMedium')?.setValue(utm_medium);
    this.requestInfoForm.get('utmCampaign')?.setValue(utm_campaign);
    this.requestInfoForm.get('utmContent')?.setValue(utm_content);
    this.requestInfoForm.get('utmTerm')?.setValue(utm_term);
    this.requestInfoForm.get('loadTest')?.setValue(loadtest);
  }
  setUserAgentData() {
    this.userAgentData = this.userAgentService.getUAParams();
    if (this.userAgentData) {
      this.requestInfoForm.get('browser')?.setValue(this.userAgentData.browser?.name);
      this.requestInfoForm.get('browserVersion')?.setValue(this.userAgentData.browser?.version);
      this.requestInfoForm.get('operatingSystem')?.setValue(this.userAgentData.os?.name);
      this.requestInfoForm.get('deviceCategory')?.setValue(this.userAgentData.device?.type);
    }
  }

  setGoogleAnalyticsData() {
    const gaCookie = this.cookieHandler.getCookie('_ga') || "";
    const gaTrackingId = "G-CZV35KLL6L";
    this.requestInfoForm.get('gaCookie')?.setValue(gaCookie);
    this.requestInfoForm.get('gatrackid')?.setValue(gaTrackingId);
  }

  buildForm() {
    this.requestInfoForm = this.fb.group({
      gatrackid: [''],
      name: [''],
      firstName: ['',
        [
          Validators.required,
          Validators.pattern("^\s*[a-zA-Z]+(([',. -][a-zA-Z])?[a-zA-Z]*){1}\s*$")
        ]],
      lastName: ['',
        [
          Validators.required,
          Validators.pattern("^\s*[a-zA-Z]+(([',. -][a-zA-Z])?[a-zA-Z]*){1}\s*$")
        ]],
      address: [''],
      city: [''],
      school: [''],
      state: [''],
      zip: [''],
      phoneCountryCode: [''],
      phone: ['', Validators.required],
      country: ['',
      [
        Validators.required
      ]],
      email: [''],
      sifEmail: ['',
      [
        Validators.required,
        Validators.pattern(/^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/)
      ]
    ],
      military: [''],
      bsDegree: [''],
      areaOfStudy: [{ value: '', disabled: true },
      [
        Validators.required
      ]],
      programName: [{ value: '', disabled: true },
      [
        Validators.required
        ]],
      program: ['',
      [
        Validators.required
        ]],
      degree: ['',
      [
        Validators.required
      ]],
      campusCode: [''],
      education: [''],
      startDate: [{ value: '', disabled: true },
      [
        Validators.required
      ]],
      url: [''],
      browser: [''],
      browserVersion: [''],
      operatingSystem: [''],
      deviceCategory: [''],
      queryString: [''],
      gdpr: [''],
      directLeadID: [''],
      source: [this.constant.SIFSourceID],
      vendor: [this.constant.SIFVendorID],
      affiliateID: [''],
      channel: [''],
      campaignID: [''],
      originationType: [''],
      gauserid: [''],
      loadTest: [''],
      gaCookie: [''],
      gaClientID: [''],
      utmSource: [''],
      utmMedium: [''],
      utmCampaign: [''],
      utmContent: [''],
      utmTerm: [''],
      hideReCaptcha: 'false',
      recaptchaChallengeSiteKey: [this.constant.ReCaptchaChallengePublicKey],
      recaptchaChallengeToken: [''],
      recaptchaSiteKey: [this.constant.ReCaptchaPublicKey],
      recaptchaToken: ['']
    });
  }
  get formControl() {
    return this.requestInfoForm.controls;
  }

  preFillForm() {
      // prefill degree
      this.requestInfoForm.get('degree')?.setValue(this.degreePreselected);
      let e = {
        target: {
          value: this.degreePreselected
        }
      }
      this.changedDegreeLevel(e);

      // prefill aos
      this.requestInfoForm.get('areaOfStudy')?.setValue(this.aosPreselected);
      e = {
        target: {
          value: this.aosPreselected
      }}
      this.changedAOS(e)

      // prefill program
      this.requestInfoForm.get('programName')?.setValue(this.programPreselected);
      e = {
        target: {
          value: this.programPreselected
      }}
      this.changedProgram(e);
  }


  checkIfInternational() {
    this.isDomesticSub = this.geoLocationService.isDomestic.subscribe((data: boolean) => {      
      this.isDomestic = data;
      this.showCountryField = !this.isDomestic;
      this.setZipValidators();
      this.populateCountryCode();
      this.setPhoneValidators();
      this.maskphone = this.isDomestic;
      if(this.isDomestic){
        this.disclaimerAccepted = true
      }
      this.internationalValidationCompleted = true;
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    });
  }

  triggerYCBM() {
    this.ycbmSubmitted = true;
    
    this.sifService.postYCBM(this.sifData).subscribe(
      res => {
        const bookingUrl = res?.BookingAppointmentLink;
        this.ycbmService.showYCBMForm(bookingUrl, this.sifData, this.leadImportId);
      },
      error => {
        this.ycbmService.showYCBMForm('', this.sifData, this.leadImportId);
      }
    );
  }

  updateFieldValue(field: string) {
    this.requestInfoForm.get(field)?.setValue(this.requestInfoForm.get(field)?.value);
  }

  skipYCBM() {
    this.formSubmitted = true;
    this.ycbmSubmitted = false;
    this.isSkip = true;
  }

  onSubmit() {
    if (!this.requestInfoForm.valid) {
      this.requestInfoForm.markAllAsTouched();
      return;
    }
    if(this.isGDPR && !this.disclaimerAccepted) {
      this.openModal();
      return;
    }
    //Avoid double clicking
    if (this.isSubmitting) {
      return;
    }

    this.sifData = this.requestInfoForm.value;
    delete this.sifData.programName;
    this.sifData.military = this.sifData.military ? 'Yes' : 'No';  

    // Adds isDomestic value to sifData
    this.sifData.isDomestic = this.isDomestic.toString();

    // remove till here.
    this.isSubmitting = true;

    this.sifService.postSif(this.sifData).subscribe({
      next: (res) => {
        this.leadImportId = res.LeadImportNo;
        this.showBookingButton = this.ycbmService.showYCBMButton(this.zipCode?.c, this.sifData);
        this.formSubmitted = true;
        this.sifData.zip = res.HidZIP;
        this.dataLayer.GTMDataLayerPush('SIFInteractSubmit', 'Success', '', 'Submit Your Request', this.sifData, this.leadImportId);

        this.cdr.markForCheck();
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.dataLayer.GTMDataLayerPush('SIFInteractSubmit', 'Fail', err.message, 'Submit Your Request', this.sifData, '');
      }
    }
    );
  }

  /**
   * Method to get the page data based on the url fragment
   */
  private getPageData() {
    const heroQuery = gql`query sifFullFormUMass($ID: String!){
      sifFullFormUMass(id: $ID) {
        ... SIF_FORM
      }
    }
    ${SIF_FORM}
    ${LINK_FIELDS}`;
  
    try {
      this.pageDataSub = this.contentfulService.getPageData(heroQuery, { ID: this.heroId }).subscribe(res => {
        this.formData = res?.data?.sifFullFormUMass;
      });
    } catch (e) {
      console.error("failed gql query", e);
    }
  }

  validateZipCode(event: any) {
    var zipcode = event.target.value;

    // The maxlength=5 and minlength=5 handldes the validation of the entered value's length.
    // To avoid calling the api multiple times, the zip code validation will be done only when the input's value is 5 digits long.
    if (zipcode.length == 5) {
      this.sifService.zipcodeValidate(zipcode).subscribe((res) => {
        this.zipCode = res;

        if (!this.zipCode.z) {
          this.requestInfoForm.get('zip')?.setErrors({ 'incorrect': true });
        } else {
          this.requestInfoForm.get('zip')?.setValue(this.zipCode.z)
          this.requestInfoForm.get('state')?.setValue(this.zipCode.n)
          this.requestInfoForm.get('city')?.setValue(this.zipCode.y)
        }
      });
    }
  }

  populateDegreeLevel() {
    this.degreeLevel = programData.levels;
    this.requestInfoForm.get('degree')?.setValue('');
  }

  changedDegreeLevel(e: any) {
    this.selectedDegreeLevel = programData.levels.find((item: any) => item.code == e.target.value);
    this.aos = this.selectedDegreeLevel.areas;
    this.requestInfoForm.get('areaOfStudy')?.enable();
    this.requestInfoForm.get('programName')?.setValue('');
    this.requestInfoForm.get('programName')?.disable();
    this.requestInfoForm.get('startDate')?.setValue('');
    this.requestInfoForm.get('startDate')?.disable();

    if(this.aos.length == 1){
      this.requestInfoForm.get('areaOfStudy')?.setValue(this.aos[0]);
      let e = {
        target: {
        value: this.aos[0]
      }}
      this.changedAOS(e)
    } else {
      this.requestInfoForm.get('areaOfStudy')?.setValue('');
    }
  }

  changedAOS(e: any) {
    this.programList = this.selectedDegreeLevel.Program[0][e.target.value];
    this.programs = this.programList.map((item: { name: any; }) => item.name);
    this.requestInfoForm.get('programName')?.enable();
    this.requestInfoForm.get('programName')?.setValue('');
    this.requestInfoForm.get('startDate')?.setValue('');
    this.requestInfoForm.get('startDate')?.disable();

    if(this.programs.length == 1){
      this.requestInfoForm.get('programName')?.setValue(this.programs[0]);
      let e = {
        target: {
        value: this.programs[0]
      }}
      this.changedProgram(e)
    } else {
      this.requestInfoForm.get('programName')?.setValue('');
    }
  }

  changedProgram(e: any) {
    const program = e.target.value;
    const programItem = this.programList.filter((item: any) => item.name == program);
    this.requestInfoForm.get('program')?.setValue(programItem[0].code);
    this.requestInfoForm.get('school')?.setValue(programItem[0].school);
    // Start Dates
    this.requestInfoForm.get('startDate')?.setValue('');
    this.getSessionDates(programItem[0].code);
    this.requestInfoForm.get('startDate')?.enable();
  }

  populateCountryCode() {
    const countryCode = countriesData.map((item: any) => item.name)
    this.countryCodes = countryCode;
   
    if (this.isDomestic) {
      this.maskphone = true;
      this.requestInfoForm.get('country')?.setValue('United States');
      this.requestInfoForm.get('phoneCountryCode')?.setValue("1");
    } else {
      this.maskphone = false;
      this.requestInfoForm.get('country')?.setValue('');
    }
  }

  changedCountryCode(e: any) {
    const country = countriesData.find((item: any) => item.name === e.target.value);
    this.requestInfoForm.get('phoneCountryCode')?.setValue("" + country['data-code']);
    const isUnitedStates = country?.name == 'United States';
    this.isDomestic = isUnitedStates;
    this.setPhoneValidators();

    // GDPR
    this.isGDPR = country.isGDPR == "Yes";
    if (!this.isGDPR) {
      this.disclaimerAccepted = true
    } else {
      this.disclaimerAccepted = false
    }
    this.requestInfoForm.get('gdpr')?.setValue(country.isGDPR);

    // Phone
    this.maskphone = this.isDomestic;
    this.maskPhone(isUnitedStates);

    // Zip code
    if(isUnitedStates){
      this.requestInfoForm.get('zip')?.setValidators([
        Validators.required,
        Validators.pattern("^[0-9]{5}$")
      ]);
    } else {
      this.requestInfoForm.get('zip')?.setErrors(null);
      this.requestInfoForm.get('zip')?.setValidators([]);
      this.requestInfoForm.get('phoneCountryCode')?.setValidators([
        Validators.required,  
        Validators.pattern("^[0-9]*$")])
    }
  }

  setZipValidators() {
    if (this.isDomestic) {
      this.maskphone = true;
      this.requestInfoForm.get('zip')?.setValidators([
        Validators.required,
        Validators.pattern("^[0-9]{5}$")
      ]);
    } else {
      this.maskphone = false;
      this.requestInfoForm.get('zip')?.setErrors(null)
      this.requestInfoForm.get('zip')?.setValidators([]);
    }
  }

  setPhoneValidators() {
    if (this.isDomestic) {
      this.requestInfoForm.get('phone')?.setValidators(
        [
          Validators.required,
          Validators.pattern("^[2-9][0-9]{2}[- ]?[0-9]{3}[- ]?([0-9]{4})$"),
          Validators.minLength(10)
        ]);
    } else {
      this.requestInfoForm.get('phone')?.setValidators(
        [
          Validators.required,
          Validators.minLength(6)
        ]);
    }
  }

  maskPhone(usphone: boolean) {
    let phone = this.requestInfoForm.get('phone')?.value;

    if (phone) {
      phone = phone.split('-').join('');

      if (phone.length < 10 && phone.length > 3) {
        let digit = [phone.slice(0, 3), '-', phone.slice(3, 6), '-', phone.slice(6)].join('');
        usphone ?
          this.requestInfoForm.get('phone')?.setValue(digit) :
          this.requestInfoForm.get('phone')?.setValue(phone);
      } else {
        let digit = phone.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
        usphone ?
          this.requestInfoForm.get('phone')?.setValue(digit[1] + '-' + digit[2] + '-' + digit[3]) :
          this.requestInfoForm.get('phone')?.setValue(digit['input']);
      }
    }
  }

  captchaChallengeResolved(captchaResponse: any) {
    this.requestInfoForm.get('recaptchaChallengeToken')?.setValue(captchaResponse);
    if(captchaResponse) {
      this.isReCaptchaValid = true;
    }
    this.setRecaptchaV3();
  }

  setRecaptchaValues() {
    this.recaptchaLoaded = this.showRecaptcha(); 
    this.recaptchaSize = "normal";

    if (this.commonService.isPlatformBrowser() && window.innerWidth < 425) {      
      this.recaptchaSize = "compact";
    }            
  }

  setRecaptchaV3() {
    try {
      const recaptchaToken = this.requestInfoForm.get('recaptchaToken')?.value;
      //check if hidden recaptcha value already set
      if (recaptchaToken == "") {
        const recaptchaV3Service = this.injector.get<ReCaptchaV3Service>(ReCaptchaV3Service);
        recaptchaV3Service.execute('sifsubmit').subscribe(
          (token) => {
            this.requestInfoForm.get('recaptchaToken')?.setValue(token);
          },
          (error) => {
            console.log("Recaptcha error: ", error);
          }
        );
      }      
    } catch (e) {
      //alert(e);
    }
  }  

  //check for automation parameter
  showRecaptcha() {
    const automation = this.queryStringHandler.getParameter('automation', true) || '';   
    if (automation.toLowerCase() !== "true") { 
      return true;//Do not hide
    } else {
      return false;//Hide
    }
  }

  openModal() {
    this.modalService.open();
  }

  toggleTooltip(event: Event): void {
    let element = (event.target as Element);
    element?.querySelector('.tooltiptext')?.classList?.toggle('show');
  }

  /**
   * To validate hero sif steps
   */
  validateSteps() {
    switch (this.heroSifStep) {     
      case 'Step1': this.validateStep1();
        break;
      case 'Step2': this.validateStep2();
        break;
      case 'Step3': this.validateStep3();
        break;
      case 'Step4': this.onSubmit();
        break;
    }
    this.scrollBelowHeader();
  }

  /**
   * Hero step back button functionality
   */
  backBtn() {
    switch(this.heroSifStep) {
      case 'Step2': this.heroSifStep = 'Step1';
        break
      case 'Step3': this.heroSifStep = 'Step2';
        break
      case 'Step4': this.heroSifStep = 'Step3'; 
        this.requestInfoForm.get('recaptchaChallengeToken')?.removeValidators(Validators.required);
        break;
    }
    this.scrollBelowHeader();
  }

  validateStep1() {
    if (this.requestInfoForm.get('bsDegree')?.valid && this.requestInfoForm.get('zip')?.valid) {
      this.heroSifStep = 'Step2';
      setTimeout(() => {
        this.dom.getElementById('areaOfStudy')?.focus();
      }, 150);    
    } else {
      this.requestInfoForm.get('areaOfStudy')?.markAsTouched();
      this.requestInfoForm.get('programName')?.markAsTouched();
      this.requestInfoForm.get('startDate')?.markAsTouched();
    }
    
  }


  /**
   * Hero step 2 validations
   */
  validateStep2() {
    if (this.requestInfoForm.get('areaOfStudy')?.valid && this.requestInfoForm.get('program')?.valid && this.requestInfoForm.get('startDate')?.valid) {
      this.heroSifStep = 'Step3';
      setTimeout(() => {
        this.dom.getElementById('firstName')?.focus();
      }, 150);    
    } else {
      this.requestInfoForm.get('areaOfStudy')?.markAsTouched();
      this.requestInfoForm.get('programName')?.markAsTouched();
      this.requestInfoForm.get('startDate')?.markAsTouched();
    }
    
  }

  /**
   * Hero step 3 validations
   */
  validateStep3() {
    this.requestInfoForm.get('recaptchaChallengeToken')?.clearValidators();
    this.requestInfoForm.get('recaptchaChallengeToken')?.setValue('');
    setTimeout(() => {
      this.updateLinkAttribute();
    }, 2000);
    if(this.requestInfoForm.valid) {
      this.heroSifStep = 'Step4';
      this.requestInfoForm.get('recaptchaChallengeToken')?.addValidators(Validators.required);
    } else {
      this.requestInfoForm.markAllAsTouched();
    }
    this.calculateHeroHeightForYCBM();
  }

  calculateHeroHeightForYCBM() {
    const hero = this.dom.querySelector('section.hero')
    this.heroHeight = hero?.clientHeight;
    this.commonService.desktopBaseDown$.subscribe(isDesktopBaseDown => {
      if (isDesktopBaseDown) {
        this.heroHeight = 600;
      } else {
        const hero = this.dom.querySelector('section.hero')
        this.heroHeight = hero?.clientHeight;
      }
    })
  }


  scrollBelowHeader() {
    let headerOffset = 0;
    if (window.innerWidth <= 639) {
      headerOffset = 165;
    } else if (640 <= window.innerWidth && window.innerWidth <= 1199) {
      headerOffset = 115;
    } else if (window.innerWidth >= 1200) {
      headerOffset = 160;
    }
    setTimeout(() => {
      const element = this.dom.querySelector('section.hero-sif');
      const elementPosition = element?.getBoundingClientRect().top || 0;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;
      window.scrollTo({
           top: offsetPosition,
           behavior: "instant"
      });
    }, 50);
    
  }


  getSessionDates(programId: string) {
    if (programId != null && programId != '') {
      this.programDataService.getStartDatesById(programId.split('-')[0]?.toUpperCase()).subscribe((response: any) => {
        if (response?.startAndApplyByDate?.length > 0) {
          const formattedTerms = this.startDatesService.formatStartDateItemsTermsOnly(response?.startAndApplyByDate, this.startDateTermConfig);
          if (formattedTerms.length) {
            this.sessions = formattedTerms;
          }
        }
      });
    }
  }

  //#endregion
}
