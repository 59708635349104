// Angular
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { provideClientHydration } from '@angular/platform-browser';//Non-destructive hydration
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { JwtModule } from '@auth0/angular-jwt';

// Modules
import { GraphQLModule } from './graphql.module';
import { CoreModule } from './core/core.module';
import { ComponentsModule } from './components/components.module';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './core/guards/auth.guard';

// Components
import { AppComponent } from './app.component';
import { OptimizedComboComponent } from './page-templates/optimized-combo/optimized-combocomponent';
import { PreviewModeService } from './core/services/preview-mode.service';
import { RequestInformationComponent } from './page-templates/request-information/request-information.component';
import { ErrorPageComponent } from './page-templates/error-page/error-page.component';
import { LocationStrategy } from '@angular/common';
import { PathPreserveQueryLocationStrategy } from './core/services/location-srategy.service';
import { LoginComponent } from './page-templates/login/login.component';
import { PageNotFoundComponent } from './page-templates/page-not-found/page-not-found.component';

export function initPreviewMode(preview: PreviewModeService) {
  return (): Promise<any> => { 
    return preview.setPreviewMode();
  }
}

export function tokenGetter() {
  return localStorage.getItem("jwtToken");
}

@NgModule({
  declarations: [
    AppComponent,
    OptimizedComboComponent,
    RequestInformationComponent,
    ErrorPageComponent,
    LoginComponent,
    PageNotFoundComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    CoreModule,
    GraphQLModule,
    ComponentsModule,
    ReactiveFormsModule,
    FormsModule,
    JwtModule.forRoot({ config: {tokenGetter: tokenGetter, allowedDomains: ["localhost"], disallowedRoutes: []}}),
  ],
  providers: [ provideClientHydration(),
    AuthGuard,
//    DevelopmentGuard,
    { provide: APP_INITIALIZER, useFactory: initPreviewMode, deps: [PreviewModeService], multi: true},
    { provide: LocationStrategy, useClass: PathPreserveQueryLocationStrategy }    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
