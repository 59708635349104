import { Component, Input, ViewEncapsulation,OnChanges, OnInit, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class HeroComponent implements OnChanges {
  @Input({ required: true }) public hero!: any;
  @Input() aosPreselected = '';
  @Input() degreePreselected = '';
  @Input() programPreselected = '';
  hTag: any;
  imageOpacity: any;
  bgImage: any = {};
  safeURL: any;
  
  constructor(public commonService: CommonService, private sanitizer: DomSanitizer) {
    
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.hTag = this.hero.hTag;
    this.bgImage = this.getBackgroundImage();
    this.imageOpacity = this.getImageOpacity();  
    this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.hero?.youTubeVideoLink}?controls=0&autoplay=1&mute=1&loop=1&modestbranding=1`);
  }

  /**
   * Background Image (Desktop resolutions)
   * @returns Background 
   */
  getBackgroundImage() {
    if (this.hero?.backgroundImage?.url) {
      return {
        'background-image': 'url(' + this.hero.backgroundImage.url + ')',
        'background-position-x': this.hero.imagePositionHorizontal != '' ? this.hero.imagePositionHorizontal.toLowerCase() : 'center',
        'background-position-y': this.hero.imagePositionVertical != '' ? this.hero.imagePositionVertical.toLowerCase() : 'center'
      }
    }
    return '';
  }

  getImageOpacity(){
    if(this.hero?.imageOpacity) {
      const numberString = this.hero.imageOpacity.replace("%", "");

      return `overlay-${numberString}`;
    }

    return '';
  }
}
