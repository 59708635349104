import { Injectable } from '@angular/core';
import { Apollo, TypedDocumentNode } from 'apollo-angular';
import { Observable } from 'rxjs';
import { DocumentNode } from 'graphql';
import { PreviewModeService } from './preview-mode.service';
@Injectable({
    providedIn: 'root'
})
export class ContentfulService {
    constructor(private apollo: Apollo, private preview: PreviewModeService) { }
    /**
     * To query contentful to get page data
     * @param query GQL quey
     * @param variables query variables
     * @returns
     */
    public getPageData(query: DocumentNode | TypedDocumentNode<unknown, any>, variables: any = {}): Observable<any> {
        variables.preview = this.preview.isPreviewMode();
        if(Object.hasOwn(variables, 'slug')) {
            variables.slug = this.parseSlugUrl(variables.slug)
        }
        const gqlQueryOptions: any = {
            query: query,
            variables: variables
        };
        // if preview mode, then fetch fresh data from contentful. No client caching policy will be used.
        if (this.preview.isPreviewMode()) {
            gqlQueryOptions['fetchPolicy'] = "no-cache";
        }
        return new Observable<any>(observer => {
            this.apollo.watchQuery(gqlQueryOptions).valueChanges.subscribe({
                next: ({ data, error }: any) => {
                    if (error) {
                        console.error("gql failed- ", error);
                    }
                    observer.next({ data, error });
                    observer.complete();
                }
            });
        });
    }
    private parseSlugUrl(slug: string) {
        return slug.split('?')[0];
    }
}