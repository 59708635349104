<section [ngClass]="['hero', hero.youTubeVideoLink ? 'bg-video' : '']" *ngIf="hero" [ngStyle]="bgImage">
    <div class="youtube-container" *ngIf="hero.youTubeVideoLink">
        <div [ngClass]="['text-overlay']" *ngIf="hero.imageTextOverlay">{{ hero.imageTextOverlay }}</div>
        <iframe [src]="safeURL" frameborder="0"></iframe>
    </div>
    <div [ngClass]="['text-overlay', 'show-for-large']" *ngIf="hero.imageTextOverlay">{{ hero.imageTextOverlay }}</div>
    <div [ngClass]="['overlay', imageOpacity]"></div>
    <div [ngClass]="['hero-desktop-down']" [ngStyle]="bgImage" *ngIf="!hero.youTubeVideoLink">
        <div [ngClass]="['text-overlay']" *ngIf="hero.imageTextOverlay">{{ hero.imageTextOverlay }}</div>
    </div>
    <div [ngClass]="['two-column-header']">
        <div [ngClass]="['grid-container', 'hero-container']">
            <div [ngClass]="['two-column-header-container']">
                <div
                    [ngClass]="['hero-content', 'Black', hero.textPositionVertical ? 'align-v-' + hero.textPositionVertical.toLowerCase() : 'align-v-center', hero.textPositionHorizontal ? 'align-h-' + hero.textPositionHorizontal.toLowerCase() : 'align-h-left']">
                    <div [ngClass]="['preheading']">{{hero.heroPreheader}}</div>
                    <app-h-tag [hTag]="hero.hTag" [className]="'hero-header'" [content]="hero.heroHeader" *ngIf="hero.heroHeader"></app-h-tag>
                    <div *ngIf="hero.heroContent" [ngClass]="['body']">
                        {{hero.heroContent}}
                    </div>
                    <div [ngClass]="['hero-buttons-container']" *ngIf="hero.linksCollection.items.length">
                        <ng-container *ngFor="let buttonLink of hero.linksCollection.items; let i = index">
                            <app-link [childClass]="'hero-content-button'" 
                            [internalLink]="buttonLink.internalLink?.url" [linkTitle]="buttonLink.linkTitle" [target]="buttonLink.linkTarget" 
                            [externalLink]="buttonLink.externalLink" [documentLink]="buttonLink.documentLink" [linkContent]="linkContent">
                            </app-link>
                            <ng-template #linkContent>
                                <div class="cta-icon">
                                    <i class="fal fa-globe"></i>
                                </div>
                                <div class="button-inner">
                                    <span>{{ buttonLink.linkTitle }}</span>
                                </div>
                            </ng-template>
                        </ng-container>
                    </div>
                    <app-disclaimer-item *ngIf="hero.disclaimer as disclaimer" [disclaimerItem]="disclaimer"></app-disclaimer-item>                  
                </div>
                <ng-container [ngSwitch]="hero?.heroSif?.__typename">
                  <app-hero-sif *ngSwitchCase="'HeroSif'" [heroSif]="hero?.heroSif"></app-hero-sif>
                  <app-sif-form *ngSwitchCase="'SifFullFormUMass'"
                                [heroSif]="true"
                                [degreePreselected]="degreePreselected"
                                [programPreselected]="programPreselected"
                                [aosPreselected]="aosPreselected" 
                                [heroId]="hero?.heroSif?.sys.id"
                                [className]="'hero-sif-full-form'"></app-sif-form>
                         
                </ng-container>
            </div>
        </div>
    </div>
</section>
