<section class='divider {{backgroundColor}}' *ngIf="startDates?.topDivider">
  <div class="grid-container">
    <div class="line-break"></div>
  </div>
</section>

<section class="start-dates {{backgroundColor}}" *ngIf="startDates">
  <div class='grid-container'>
    <div class='grid-x'>
      <ng-container *ngIf="sectionHeader || sectionSubheader">
        <div class="small-12 header-content {{headerOnly}}" [class.align-center]="startDates?.alignment?.toLowerCase() == 'center'">
          <app-h-tag *ngIf="sectionHeader" [id]="startDates.hTagId" [hTag]="startDates.headerTag" [content]="sectionHeader" [className]="'header h2'"></app-h-tag>
          <div class='subheader-content' *ngIf="sectionSubheader" [class.align-center]="startDates?.alignment?.toLowerCase() == 'center'">
            <app-contentful-rich-text [document]="sectionSubheader"></app-contentful-rich-text>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="startDateItemsArray.length">
        <div class='small-12 start-dates-slider' [ngClass]="'slides-' + startDateItemsArray.length" [class.align-center]="startDates?.alignment?.toLowerCase() == 'center'">
          <ngx-slick-carousel class="carousel" id="slick-carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <ng-container *ngFor="let sDate of startDateItemsArray">
              <div ngxSlickItem class="slide">
                <div class='start-dates-wrapper'>
                  <div class="year"><span *ngIf="showTerm">{{sDate?.termName}}</span><span *ngIf="!showTerm">{{sDate?.date?.getFullYear()}}</span></div>
                  <div class="date">{{sDate?.monthName}} {{sDate?.date?.getDate()}}</div>
                  <div class="term">{{sDate?.applyBydate}}</div>
                </div>
              </div>
            </ng-container>
          </ngx-slick-carousel>
        </div>
      </ng-container>
    </div>
    <app-disclaimer-item *ngIf="startDates?.disclaimer" [disclaimerItem]="startDates?.disclaimer" [class.align-center]="startDates?.alignment?.toLowerCase() == 'center'"></app-disclaimer-item>
  </div>
</section>
